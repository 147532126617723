import headerImage from '@static/header/codemymobile_website_2_blue_white_-Django.jpg';
import headerIcon from '@static/icons/Industries_heading_icon.svg';

const portfolioPage = {
  title: 'Portfolio',
  heading: "WE DON'T BUILD APPS, WE CRAFT APPS",
  overview: 'OUR APPS COME WITH AWESOME UI AND UX',
  headingIcon: headerIcon,
  headerImage: headerImage,
  navigation: {
    prev: {
      title: 'Technology',
      overview:
        'From basics to path-breaking technologies, we hack all of them with sheer precision and excellence. We work on varied platforms and disruptive technologies',
    },
    next: {
      title: 'Industries',
      overview:
        'There is phenomenal digital transformation and emergence of automation in all spheres of business. We have worked across a vast range of industries and cater to the finest of technical expertise required for your business needs.',
    },
  },
};

export default portfolioPage;
